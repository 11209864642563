<template>
  <div class="content-OPGlobal">
    <div class="content-global">
      <div ref="searchOCGlobal">
        <header-component-global
          sTextTitle="Operaciones de caja"
          sTextBtnAdd=""
          :bAdminPermissions="false"
          setDialog=""
          @setRangeDate="setRangeDate"
        />
        <o-p-dashboard-component-global :oItemDashboard="oItemDashboard" />
      </div>
      <div ref="tabOC">
        <v-tabs
          v-model="tab"
          class="global-tabs"
          show-arrows
          :touchless="true"
          center-active
          background-color="transparent"
        >
          <v-tab
            class="tab-oc-global global-tab"
            v-for="item in tabsItems"
            :key="item"
          >
            {{ item }}
          </v-tab>
          <v-tabs-slider class="divider-tabs"></v-tabs-slider>
        </v-tabs>
      </div>
      <v-tabs-items v-model="tab" class="global-item-tab">
        <v-tab-item>
          <o-p-component-global
            :aHeaders="aHeadersPerPaid"
            :sApiGetOC="sApiGetOC"
            :sTypeItem="3"
            @setItemDashboard="setItemDashboard"
            sTextTitle="Órdenes de compra por pagar"
            sLabelNameFilter="Buscador general"
            sPlaceholderName="Ingrese folio, nombre…"
            :bChangeSelectedItem="bChangeSelectedItem"
            :oDateRange="oDateRange"
          />
        </v-tab-item>
        <v-tab-item>
          <o-p-component-global
            :aHeaders="aHeadersPaid"
            :sApiGetOC="sApiGetOC"
            :sTypeItem="3"
            @setItemDashboard="setItemDashboard"
            sTextTitle="Órdenes de compra por pagar"
            sLabelNameFilter="Buscador general"
            sPlaceholderName="Ingrese folio, nombre…"
            :bChangeSelectedItem="bChangeSelectedItem"
            :oDateRange="oDateRange"
          />
        </v-tab-item>
      </v-tabs-items>
    </div>
    <!-- <footer-component-global /> -->
  </div>
</template>

<script>
export default {
  data() {
    return {
      tab: 0,
      screenHeight: 0,
      heightMax: 0,
      tabsItems: {
        perPaid: "Por pagar",
        paid: "Finalizadas",
      },
      aHeadersPerPaid: [
        {
          text: "Folio",
          sortable: false,
          value: "sReference",
          class: "global-header-table first-col-tb",
          cellClass: "global-body-table first-col-tb ",
        },
        {
          text: "Tipo de operación",
          sortable: false,
          value: "sOrderType",
          class: "global-header-table ",
          cellClass: "global-body-table ",
        },
        {
          text: "Nombre",
          sortable: false,
          value: "sActorName",
          class: "global-header-table ",
          cellClass: "global-body-table",
        },
        {
          text: "Monto acumulado",
          sortable: false,
          value: "dAmountFormat",
          align: "end",
          class: "global-header-table ",
          cellClass: "global-body-table ",
        },
        {
          text: "Kilogramos",
          sortable: false,
          value: "dTotalWeight",
          align: "end",
          class: "global-header-table ",
          cellClass: "global-body-table ",
        },
        {
          text: "Estado",
          sortable: false,
          value: "sStateName",
          class: "global-header-table ",
          cellClass: "global-body-table ",
        },
        {
          text: "Fecha de creación",
          sortable: false,
          value: "tCreatedAt",
          class: "global-header-table",
          cellClass: "global-body-table ",
        },
        {
          text: "Acciones",
          value: "accions",
          align: "center",
          class:
            "global-header-table  global-header-accions global-col-header-accion",
          cellClass:
            "global-body-table global-body-accions global-col-body-accion",
        },
      ],
      aHeadersPaid: [
        {
          text: "Folio",
          sortable: false,
          value: "sReference",
          class: "global-header-table first-col-tb",
          cellClass: "global-body-table first-col-tb ",
        },
        {
          text: "Tipo de operación",
          sortable: false,
          value: "sOrderType",
          class: "global-header-table ",
          cellClass: "global-body-table ",
        },
        {
          text: "Nombre",
          sortable: false,
          value: "sActorName",
          class: "global-header-table ",
          cellClass: "global-body-table",
        },
        {
          text: "Monto acumulado",
          sortable: false,
          value: "dAmountFormat",
          align: "end",
          class: "global-header-table ",
          cellClass: "global-body-table ",
        },
        {
          text: "Kilogramos",
          sortable: false,
          value: "dTotalWeight",
          align: "end",
          class: "global-header-table ",
          cellClass: "global-body-table ",
        },
        {
          text: "Estado",
          sortable: false,
          value: "sStateName",
          class: "global-header-table ",
          cellClass: "global-body-table ",
        },
        {
          text: "Fecha de creación",
          sortable: false,
          value: "tCreatedAt",
          class: "global-header-table",
          cellClass: "global-body-table ",
        },
        {
          text: "Acciones",
          value: "accions",
          align: "center",
          class:
            "global-header-table  global-header-accions global-col-header-accion",
          cellClass:
            "global-body-table global-body-accions global-col-body-accion",
        },
      ],
      sApiGetOC: "",
      bChangeSelectedItem: false,
      oDateRange: {},
      oItemDashboard: {},
    };
  },
  updated() {
    this.matchHeight();
  },
  created() {
    window.addEventListener("resize", this.handleResize);
    this.handleResize();
    window.addEventListener("resize ", this.matchHeight);
    this.matchHeight();
  },
  destroyed() {
    window.removeEventListener("resize", this.handleResize);
    window.addEventListener("resize ", this.matchHeight);
  },
  beforeMount() {
    this.setApiGetOC();
  },
  methods: {
    //#region RESIZE PAGE
    matchHeight() {
      this.$nextTick((e) => {
        if (this.$refs.searchOCGlobal !== undefined) {
          this.heightMax =
            this.$refs.searchOCGlobal.clientHeight +
            this.$refs.tabOC.clientHeight;
          this.heightMax = this.heightMax + 143; //123 IS NAVBAR
        }
        if (window.innerWidth > 600) {
          this.screenHeight = window.innerHeight - 100;
        } else {
          this.screenHeight = window.innerHeight - 100;
        }
      });
    },
    handleResize: function () {
      this.matchHeight();
    },
    //#endregion RESIZE PAGE
    setApiGetOC() {
      this.tab = this.sTabPositionOrderPayable;

      switch (this.sTabPositionOrderPayable) {
        case 0:
          this.sApiGetOC = `${URI}/api/sp/v1/orders/cash-register/pending`;
          // this.$store.commit("refresher", true);
          break;
        case 1:
          this.sApiGetOC = `${URI}/api/sp/v1/orders/cash-register/payed`;
          // this.$store.commit("refresher", true);
          break;

        default:
          break;
      }
    },
    setItemDashboard(oItemDashboard) {
      this.oItemDashboard = oItemDashboard;
    },
    setRangeDate(oDateRange) {
      this.oDateRange = oDateRange;
    },
  },
  computed: {
    sTabPositionOrderPayable() {
      return this.$store.state.sTabPositionOrderPayable;
    },
  },
  watch: {
    tab() {
      this.$store.commit("setTabPositionOrderPayable", this.tab);
      this.setApiGetOC();
      // this.$store.commit("refresher", true);
      this.bChangeSelectedItem = !this.bChangeSelectedItem;
      this.$store.commit("refresher", true);
    },
  },
};
</script>

<style></style>
